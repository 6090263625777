import telenorid from "~/telenorid/telenorid.js";
import { decodeJwt } from "jose";
import { formatBirthDate } from "~/components/broadband-consumer/FixedConsumerOrder/util/customer";

export interface IUserAuth {
	id_token: string;
	access_token: string;
	expires_at: number;
	expired: boolean;
	profile: IUserProfile;
	segments?: string[];
}

export interface IUserProfile {
	birthdate: string;
	family_name: string;
	given_name: string;
	preferred_username: string;
	kurtid: string;
	amr: string;
	acr: string;
}

export interface ICustomer {
	access_token: IUserAuth["access_token"];
	id_token: IUserAuth["id_token"];
	address: unknown;
	kurtId: string;
	firstName: string;
	lastName: string;
	birthDate: string;
	isOrganization: boolean;
	postalAddress?: unknown;
	amr: string[];
	acr: string;
	segments: string[];
}

export const useUserStore = defineStore("user", () => {
	const userAgent = ref<string>("");

	const customer = ref<ICustomer | null>(null);

	const showLogoutNotice = ref<boolean>(false);

	const attemptedLogin = ref<boolean>(false);

	const isUserLoggedIn = computed(() => !!customer.value);

	const kurtId = computed((): string => {
		if (customer.value) return customer.value.kurtId;
		return "";
	});

	const updateCustomer = (newCustomer: ICustomer) => {
		customer.value = { ...customer.value, ...newCustomer };
	};

	const customerName = computed(() => {
		if (!customer.value?.firstName) {
			return "";
		}
		return [customer.value.firstName, customer.value.lastName].filter((value) => !!value).join(" ");
	});

	const formattedBirthDate = computed(() => {
		if (!customer.value?.birthDate) {
			return "";
		}
		return formatBirthDate(customer.value.birthDate);
	});

	const setUser = async (newUser: IUserAuth | ICustomer): Promise<ICustomer | IUserAuth> => {
		if ((newUser as IUserAuth).profile) {
			const userAuth = newUser as IUserAuth;
			const decodedUserData: any = decodeJwt(newUser.id_token);

			const customerObject: ICustomer = {
				access_token: userAuth.access_token,
				id_token: userAuth.id_token,
				kurtId: userAuth.profile.kurtid,
				firstName: userAuth.profile.given_name,
				lastName: userAuth.profile.family_name,
				birthDate: userAuth.profile.birthdate,
				isOrganization: false,
				address: {},
				postalAddress: {},
				segments: (userAuth.segments && Array.from(new Set([...userAuth.segments, "LoggedIn"]))) || [],
				amr: decodedUserData.amr,
				acr: decodedUserData.acr,
			};

			customer.value = customerObject;

			return customerObject;
		} else if ((newUser as ICustomer).firstName) {
			customer.value = newUser as ICustomer;
			return newUser as ICustomer;
		} else {
			throw new Error("Could not set user, profile is missing");
		}
	};

	async function getOrSetUser(): Promise<ICustomer | IUserAuth> {
		if (customer.value) {
			return customer.value;
		} else if (!attemptedLogin.value) {
			try {
				const user = await telenorid.getOrLoginUserFromTelenorId();
				if (user) {
					await setUser(user);
					return customer.value;
				} else {
					return;
				}
			} catch {
				// Silent SSO login failed
				return;
			} finally {
				attemptedLogin.value = true;
			}
		}
	}

	const clearCustomer = () => {
		customer.value = null;

		useContactDetailsStore().contactDetails = null;

		showLogoutNotice.value = true;

		setTimeout(() => {
			showLogoutNotice.value = false;
		}, 5500);
	};

	const logout = async () => {
		return telenorid.signoutRedirect();
	};

	return {
		userAgent,
		customer,
		customerName,
		formattedBirthDate,
		showLogoutNotice,
		isUserLoggedIn,
		attemptedLogin,
		kurtId,
		updateCustomer,
		setUser,
		getOrSetUser,
		clearCustomer,
		logout,
	};
});
