import filterComponent from "~/helpers/segmentation/filterComponent";
import { trackingPluginExclusion as EXCLUDED_ROUTES, routeIsExcluded } from "../../config/excludedRoutes";

import hash from "~/helpers/crypto/hash";

export default function ({ $store, config = {}, path = null, customPageTokens = undefined, isPreview = false }) {
	const buildUrlWithoutParams = (urlWithParams, host) => {
		if (config.hardCodedHostname) host = config.hardCodedHostname;
		if (!host.startsWith("http")) host = "https://" + host;
		return host + urlWithParams.split("?")[0];
	};

	const state = $store.state;
	const tracking = {};

	const pageStore = usePageStore();
	const userStore = useUserStore();
	const page = pageStore?.page;
	const metadata = page?.metadata;
	const disableTracking = !!metadata?.disableTracking;

	if (disableTracking || isPreview || routeIsExcluded({ path: path }, EXCLUDED_ROUTES, false)) {
		tracking.use_tealium = false;
		return tracking;
	}

	tracking.use_tealium = config.use_tealium;

	if (!tracking.use_tealium) {
		return tracking;
	}

	tracking.tealium_profile = config.tealium_profile;
	tracking.tealium_env = config.tealium_env;
	tracking.utag_sync_version = config.utag_sync_version || "1.0";

	// Segmentation
	if (userStore.customer?.segments) tracking.gizmo_segment = userStore.customer.segments.join(",");

	if (userStore.customer?.kurtId) tracking.kurt = userStore.customer.kurtId;

	const cleanPhoneNumber = (phoneNumber) => {
		try {
			// Remove non digits, e.g. +47 999 88 777 -> 4799988777
			let cleanPhoneNumber = phoneNumber.replace(/\D+/g, "");

			// Remove potential leading zeroes (e.g. 0047)
			cleanPhoneNumber = parseInt(cleanPhoneNumber, 10).toString();

			// Add country code if missing
			return cleanPhoneNumber.length === 8 ? `47${cleanPhoneNumber}` : cleanPhoneNumber;
		} catch (e) {
			console.error(e);
			return undefined;
		}
	};

	if (useContactDetailsStore().phoneNumber)
		tracking.hashed_phoneNumber = hash(cleanPhoneNumber(useContactDetailsStore().phoneNumber));

	if (useContactDetailsStore().emailAddress) tracking.hashed_emailAddress = hash(useContactDetailsStore().emailAddress);

	const segmentedContent = page?.layouts
		.filter((layout) => layout)
		.filter((layout) => filterComponent(layout, state))
		.map((layout) => {
			return layout.columns.map((column) => {
				return column
					.filter((component) => component)
					.filter((component) => component.segmentation)
					.filter((component) => filterComponent(component, state));
			});
		})
		.filter((layout) => layout.length > 0)
		.flat()
		.filter((column) => column.length > 0)
		.flat();

	if (segmentedContent) {
		tracking.gizmo_segmented_content_ID = segmentedContent.map((comp) => comp.id).join(";");
		tracking.gizmo_segmented_component_name = segmentedContent
			.map((comp) => comp.schema?._id || comp.schema?.name)
			.join(";");
	}

	if (path) {
		let url;
		let pageTokens = [];

		if (!state.host) {
			$store.commit("setHost", window.location.host);
		}

		try {
			url = new URL(buildUrlWithoutParams(path, state.host));
		} catch {
			console.warn("Need a full url - analytics data not setup correctly!");
			return tracking;
		}

		if (customPageTokens?.constructor === Array) pageTokens = customPageTokens;
		else if (customPageTokens?.constructor === String) pageTokens = [customPageTokens];
		else if (metadata?.analyticsPageName) pageTokens = [metadata.analyticsPageName];
		else if (metadata?.title) pageTokens = [metadata.title];

		tracking.server = config.hardCodedHostname || config.analytics_server || url.hostname; // Note that this sometimes use the Azure server address;
		tracking.site_section = getSiteSection(url);
		tracking.page_hierarchy = getPageStructure(url.pathname, pageTokens, "/");
		tracking.page_name = getPageStructure(url.pathname, pageTokens, ":");
	}
	return tracking;
}

function getSiteSection(url) {
	const { hostname, pathname } = url;
	let section, site;

	if (pathname.startsWith("/bedrift/")) section = "B";
	else section = "P";

	if (hostname.indexOf("nettbutikk.telenor.no") > -1) {
		site = "Nettbutikk";
	} else if (hostname.indexOf("infoweb.telenor.no") > -1) {
		site = "Infoweb";
	} else if (
		hostname.indexOf("telenor.no") > -1 ||
		hostname.indexOf("telenor.net") > -1 ||
		hostname.indexOf("localhost") > -1
	) {
		if (pathname.startsWith("/privat/mine-sider/") || pathname.startsWith("/privat/minesider/")) site = "Mine sider";
		else if (pathname.startsWith("/bedrift/min-bedrift/") || pathname.startsWith("/bedrift/minbedrift/"))
			site = "Min Bedrift";
		else if (pathname.startsWith("/mitt-telenor/")) site = "Mitt Telenor";
		else {
			if (section === "B") site = "Bedrift";
			else site = "Privat";
		}
	}

	if (section && site) return section + "-" + site;
	return site; // Note that if site is not set it's returned as undefined and will not be used in the variable setup
}

function getPageStructure(url, tokens = [], separator) {
	url = url.split("?")[0]; // Remove the query params if there
	const urlPaths = url.split("/");
	urlPaths.pop();
	if (urlPaths.length > 1) urlPaths[1] = urlPaths[1].charAt(0).toUpperCase() + urlPaths[1].slice(1);
	let returnPath = "Hjem" + urlPaths.join(separator);
	let tokenStructure = tokens.length ? tokens?.join(separator) : "";
	returnPath += separator;
	returnPath += tokenStructure ? tokenStructure : urlPaths[urlPaths.length - 1]; // If title is not set use the last path as the pages title value
	return returnPath;
}
